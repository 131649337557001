import React, { useState } from 'react'
import TextInput from '../../../../components/Inputs/TextInput'
import DatePicker from '../../../../components/Inputs/DatePicker'
import { UPLOADS_ENDPOINT } from '../../../../server-parameters'
import { yearsAgo } from '../../../../utils/helpers'
import {
  validateName,
  validateDate,
  passportDigits,
  passportText,
  smartValidateString,
} from '../../../../validate/validators'
import { Country, ScreenProps } from '../../../../types/common-types'
import { actions, RUSSIAN_PASSPORT } from '../../../../state-actions-reducers'
import FileInput from '../../../../components/Inputs/FileInput'
import IdentityExist from '../../../../components/IdentityExist'

export default function KyrgyzstanForm({
  state,
  lang,
  invalidFields,
  dispatch,
  accountForm,
}: ScreenProps) {
  const [focus, setFocus] = useState('')
  return (
    <>
      <div className="col-2 col-xs-12">
        {/* PASSPORT SERIES / NUMBER */}
        <TextInput
          disabled={!state.agreed}
          lang={lang}
          content={accountForm.passportNumber}
          onChange={passportNumber => {
            dispatch({
              type: actions.PASSPORT_NUMBER,
              data: passportNumber,
            })
          }}
          value={state.passportNumber}
          required
          valid={
            passportDigits(state.passportNumber) &&
            !invalidFields.includes('passportNumber')
          }
          onBlur={() => setFocus('')}
          mask={undefined}
        />
      </div>
      <IdentityExist
        state={state}
        accountForm={accountForm}
        lang={lang}
        dispatch={dispatch}
        show={!state.isIdentityOk}
      />
      <div className="col-2 col-xs-12">
        {/* PASSPORT_PERSON_NUMBER */}
        <TextInput
          disabled={!state.agreed}
          lang={lang}
          content={accountForm.passportPersonNumber}
          onChange={passportPersonNumber => {
            dispatch({
              type: actions.PASSPORT_PERSON_NUMBER,
              data: passportPersonNumber,
            })
          }}
          value={state.passportPersonNumber}
          required
          valid={
            validateName(state.passportPersonNumber, false) &&
            !invalidFields.includes('passportPersonNumber')
          }
          onBlur={() => setFocus('')}
          mask={undefined}
        />
      </div>
      <div className="col-6 col-xs-12">
        {/* PASSPORT ISSUED BY */}
        <TextInput
          disabled={!state.agreed}
          lang={lang}
          content={accountForm.issuedBy}
          onChange={passportIssuedBy =>
            dispatch({
              type: actions.PASSPORT_ISSUED_BY,
              data: passportIssuedBy.toLocaleUpperCase(),
            })
          }
          value={state.passportIssuedBy}
          required
          valid={
            state.passportIssuedBy.length > 0
              ? smartValidateString(state.passportIssuedBy) &&
                !invalidFields.includes('passportIssuedBy')
              : !invalidFields.includes('passportIssuedBy')
          }
          onBlur={() => setFocus('')}
        />
      </div>
      <div className="col-2 col-xs-12">
        {/* PASSPORT ISSUE DATE */}
        <DatePicker
          disabled={!state.agreed}
          lang={lang}
          accountForm={accountForm}
          accountFormKey="passportIAt"
          onChange={data => dispatch({ type: actions.PASSPORT_IAT, data })}
          value={state.passportIAt}
          required
          valid={!invalidFields.includes('passportIAt')}
          onBlur={() => setFocus('')}
          curr={
            state.birthDate
              ? new Date(
                  new Date(state.birthDate).setFullYear(
                    new Date(state.birthDate).getFullYear() + 14
                  )
                )
              : new Date()
          }
          min={
            state.birthDate
              ? new Date(
                  new Date(state.birthDate).setFullYear(
                    new Date(state.birthDate).getFullYear() + 14
                  )
                )
              : new Date(new Date().setFullYear(new Date().getFullYear() - 67))
          }
          max={new Date()}
        />
      </div>
      <div className="col-2 col-xs-12">
        {/* PASSPORT EXPIRY DATE */}
        <DatePicker
          disabled={!state.agreed}
          lang={lang}
          accountForm={accountForm}
          accountFormKey="passportExpiryDate"
          onChange={data =>
            dispatch({ type: actions.PASSPORT_EXPIRY_DATE, data })
          }
          value={state.passportExpiryDate}
          required
          valid={!invalidFields.includes('passportExpiryDate')}
          onBlur={() => setFocus('')}
          min={new Date()}
          max={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
        />
      </div>
      <div className="col-4 col-xs-12">
        <FileInput
          disabled={!state.agreed}
          lang={lang}
          url={UPLOADS_ENDPOINT}
          autorization={state.formId}
          target={state.passportImages}
          content={accountForm.mainImageFile}
          dispatch={dispatch}
          onChange={data => {
            dispatch({
              type: actions.MAIN_IMAGE_FILE,
              data: data,
            })
          }}
          valid={!invalidFields.includes('mainImageFile')}
          documentType="Passport1"
          value={state.mainImageFile[state.mainImageFile.length - 1]}
        />
      </div>
      <div className="col-4 col-xs-12">
        <FileInput
          disabled={!state.agreed}
          lang={lang}
          url={UPLOADS_ENDPOINT}
          autorization={state.formId}
          target={state.passportImages}
          content={accountForm.vizaImageFile}
          dispatch={dispatch}
          onChange={data => {
            dispatch({
              type: actions.ADDRESS_IMAGE_FILE,
              data: data,
            })
          }}
          value={state.addressImageFile[state.addressImageFile.length - 1]}
          valid={!invalidFields.includes('addressImageFile')}
          documentType="Passport2"
        />
      </div>
    </>
  )
}
