import React, { useState } from 'react'
import TextInput from '../../../../../components/Inputs/TextInput'
import DatePicker from '../../../../../components/Inputs/DatePicker'
import { UPLOADS_ENDPOINT } from '../../../../../server-parameters'
import { yearsAgo } from '../../../../../utils/helpers'
import {
  validateName,
  validateDate,
  passportDigits,
  passportText,
  smartValidateString,
} from '../../../../../validate/validators'
import { Country, ScreenProps } from '../../../../../types/common-types'
import { actions } from '../../../../../state-actions-reducers'
import FileInput from '../../../../../components/Inputs/FileInput'

export default function KazakhstanForm({
  state,
  lang,
  invalidFields,
  dispatch,
  accountForm,
}: ScreenProps) {
  const [focus, setFocus] = useState('')
  return (
    <>
      <div className="col-4 col-xs-12">
        {/* PASSPORT_CARD_NUMBER */}
        <TextInput
          testName={'passportCardNumber'}
          testErrorName={'error-passportCardNumber'}
          disabled={!state.agreed}
          lang={lang}
          content={accountForm.passportUdvNumber}
          onChange={passportCardNumber => {
            dispatch({
              type: actions.PASSPORT_CARD_NUMBER,
              data: passportCardNumber,
            })
          }}
          value={state.passportCardNumber}
          required
          valid={
            validateName(state.passportCardNumber, false) &&
            !invalidFields.includes('passportCardNumber')
          }
          onBlur={() => setFocus('')}
          mask={'999999999'}
        />
      </div>
      <div className="col-8 col-xs-12">
        {/* PASSPORT ISSUED BY */}
        <TextInput
          testName={'passportIssuedBy'}
          testErrorName={'error-passportIssuedBy'}
          disabled={!state.agreed}
          lang={lang}
          content={accountForm.issuedBy}
          onChange={passportIssuedBy =>
            dispatch({
              type: actions.PASSPORT_ISSUED_BY,
              data: passportIssuedBy.toLocaleUpperCase(),
            })
          }
          value={state.passportIssuedBy}
          required
          valid={
            state.passportIssuedBy.length > 0
              ? smartValidateString(state.passportIssuedBy) &&
                !invalidFields.includes('passportIssuedBy')
              : !invalidFields.includes('passportIssuedBy')
          }
          onBlur={() => setFocus('')}
        />
      </div>
      <div className="col-2 col-xs-12">
        {/* PASSPORT ISSUE DATE */}
        <DatePicker
          testName={'passportIAt'}
          testErrorName={'error-passportIAt'}
          disabled={!state.agreed}
          lang={lang}
          accountForm={accountForm}
          accountFormKey="passportIAt"
          onChange={data => dispatch({ type: actions.PASSPORT_IAT, data })}
          value={state.passportIAt}
          required
          valid={!invalidFields.includes('passportIAt')}
          onBlur={() => setFocus('')}
          curr={new Date()}
          min={new Date(new Date().setFullYear(new Date().getFullYear() - 10))}
          max={new Date()}
        />
      </div>
      <div className="col-3 col-xs-12">
        {/* PASSPORT EXPIRY DATE */}
        <DatePicker
          testName={'passportExpiryDate'}
          testErrorName={'error-passportExpiryDate'}
          disabled={!state.agreed}
          lang={lang}
          accountForm={accountForm}
          accountFormKey="passportExpiryDate"
          onChange={data =>
            dispatch({ type: actions.PASSPORT_EXPIRY_DATE, data })
          }
          value={state.passportExpiryDate}
          required
          valid={!invalidFields.includes('passportExpiryDate')}
          onBlur={() => setFocus('')}
          min={new Date()}
          max={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
        />
      </div>
      <div className="col-4 col-xs-12">
        <FileInput
          testName={'udvFileMainFiscal'}
          testErrorName={'error-udvFileMainFiscal'}
          disabled={!state.agreed}
          lang={lang}
          url={UPLOADS_ENDPOINT}
          autorization={state.formId}
          target={state.documentsImages}
          content={accountForm.udvFileMainFiscal}
          dispatch={dispatch}
          onChange={data => {
            dispatch({
              type: actions.F_SCAN_DIRECTORS_PASSPORT,
              data: data,
            })
          }}
          valid={!invalidFields.includes('F_Scan_Directors_Passport')}
          documentType="Passport1"
          value={
            state.F_Scan_Directors_Passport[
              state.F_Scan_Directors_Passport.length - 1
            ]
          }
        />
      </div>
      <div className="col-4 col-xs-12">
        <FileInput
          testName={'udvFileMainAddressFiscal'}
          testErrorName={'error-udvFileMainAddressFiscal'}
          disabled={!state.agreed}
          lang={lang}
          url={UPLOADS_ENDPOINT}
          autorization={state.formId}
          target={state.documentsImages}
          content={accountForm.udvFileMainAddressFiscal}
          dispatch={dispatch}
          onChange={data => {
            dispatch({
              type: actions.F_SCAN_DIRECTORS_PASSPORT_ADDRESS,
              data: data,
            })
          }}
          valid={!invalidFields.includes('F_Scan_Directors_Passport_Address')}
          documentType="Passport2"
          value={
            state.F_Scan_Directors_Passport_Address[
              state.F_Scan_Directors_Passport_Address.length - 1
            ]
          }
        />
      </div>
    </>
  )
}
