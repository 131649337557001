import React, { useState } from 'react'
import TextInput from '../../../../../components/Inputs/TextInput'
import DatePicker from '../../../../../components/Inputs/DatePicker'
import { UPLOADS_ENDPOINT } from '../../../../../server-parameters'
import { yearsAgo } from '../../../../../utils/helpers'
import {
  validateName,
  validateDate,
  passportDigits,
  passportText,
  smartValidateString,
} from '../../../../../validate/validators'
import { Country, ScreenProps } from '../../../../../types/common-types'
import { actions, RUSSIAN_PASSPORT } from '../../../../../state-actions-reducers'
import FileInput from '../../../../../components/Inputs/FileInput'
import { getPassportTypeTranslate } from '../../../../../hooks/useGetPassportTypeTranslate'

export default function BelarusForm({
  state,
  lang,
  invalidFields,
  dispatch,
  accountForm,
}: ScreenProps) {
  const [focus, setFocus] = useState('')
  return (
    <>
      {getPassportTypeTranslate(+state.passportType) === 'Вид на жительство' ? (
        <>
          <div className="col-2 col-xs-12">
            {/* PASSPORT PERMANENT RESIDENCY DATE */}
            <DatePicker
              testName={'passportDateIssuePermanentResidency'}
              testErrorName={'error-passportDateIssuePermanentResidency'}
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="passportDateIssuePermanentResidency"
              onChange={data =>
                dispatch({
                  type: actions.PASSPORT_DATE_ISSUE_PERMANENT_RESIDENCY,
                  data,
                })
              }
              value={state.passportDateIssuePermanentResidency}
              required
              valid={
                !invalidFields.includes(
                  'passportDateIssuePermanentResidency'
                ) &&
                validateDate({
                  current: state.passportDateIssuePermanentResidency,
                  min: yearsAgo(130),
                  max: new Date(),
                })
              }
              onBlur={() => setFocus('')}
              min={new Date(
                new Date().setFullYear(new Date().getFullYear() - 10) + (60 * 60 * 24 * 1000)
              )}
              max={new Date()}
            />
          </div>
          <div className="col-8 col-xs-12">
            {/* ISSUED BY */}
            <TextInput
              testName={'passportIssuedBy'}
              testErrorName={'error-passportIssuedBy'}
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.issuedBy}
              onChange={passportIssuedBy =>
                dispatch({
                  type: actions.PASSPORT_ISSUED_BY,
                  data: passportIssuedBy.toLocaleUpperCase(),
                })
              }
              value={state.passportIssuedBy}
              required
              valid={
                !invalidFields.includes('passportIssuedBy')
              }
              onBlur={() => setFocus('')}
            />
          </div>
          <div className="col-4 col-xs-12">
            <FileInput
              testName={'F_Scan_Directors_Passport'}
              testErrorName={'error-F_Scan_Directors_Passport'}
              disabled={!state.agreed}
              lang={lang}
              url={UPLOADS_ENDPOINT}
              autorization={state.formId}
              target={state.documentsImages}
              content={accountForm.residenceFileMainFiscal}
              dispatch={dispatch}
              onChange={data => {
                dispatch({
                  type: actions.F_SCAN_DIRECTORS_PASSPORT,
                  data: data,
                })
              }}
              valid={!invalidFields.includes('F_Scan_Directors_Passport')}
              documentType="Passport1"
              value={
                state.F_Scan_Directors_Passport[
                  state.F_Scan_Directors_Passport.length - 1
                ]
              }
            />
          </div>
          <div className="col-4 col-xs-12">
            <FileInput
              testName={'F_Scan_Directors_Passport_Address'}
              testErrorName={'error-F_Scan_Directors_Passport_Address'}
              disabled={!state.agreed}
              lang={lang}
              url={UPLOADS_ENDPOINT}
              autorization={state.formId}
              target={state.documentsImages}
              content={accountForm.residenceFileSecondFiscal}
              dispatch={dispatch}
              onChange={data => {
                dispatch({
                  type: actions.F_SCAN_DIRECTORS_PASSPORT_ADDRESS,
                  data: data,
                })
              }}
              valid={
                !invalidFields.includes('F_Scan_Directors_Passport_Address')
              }
              documentType="Passport2"
              value={
                state.F_Scan_Directors_Passport_Address[
                  state.F_Scan_Directors_Passport_Address.length - 1
                ]
              }
            />
          </div>
        </>
      ) : null}
    </>
  )
}
