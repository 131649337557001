import React, { useState } from 'react'
import TextInput from '../../../../../components/Inputs/TextInput'
import DatePicker from '../../../../../components/Inputs/DatePicker'
import { UPLOADS_ENDPOINT } from '../../../../../server-parameters'
import { yearsAgo, passportInputHandler } from '../../../../../utils/helpers'
import {
  validateName,
  validateDate,
  passportDigits,
  passportText,
  smartValidateString,
} from '../../../../../validate/validators'
import { Country, ScreenProps } from '../../../../../types/common-types'
import { actions, RUSSIAN_PASSPORT } from '../../../../../state-actions-reducers'
import FileInput from '../../../../../components/Inputs/FileInput'
import { getPassportTypeTranslate } from '../../../../../hooks/useGetPassportTypeTranslate'

export default function KyrgyzstanForm({
  state,
  lang,
  invalidFields,
  dispatch,
  accountForm,
}: ScreenProps) {
  const [focus, setFocus] = useState('')
  return (
    <>
      {getPassportTypeTranslate(+state.passportType) === 'Паспорт' ? (
        <>
          <div className="col-2 col-xs-12">
            {/* PASSPORT SERIES / NUMBER */}
            <TextInput
              testName={'passportNumber'}
              testErrorName={'error-passportNumber'}
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.documentNumberKgz}
              onChange={passportNumber => {
                dispatch({
                  type: actions.PASSPORT_NUMBER,
                  data: passportNumber,
                })
              }}
              value={state.passportNumber}
              required
              valid={
                passportDigits(state.passportNumber) &&
                !invalidFields.includes('passportNumber')
              }
              onBlur={() => setFocus('')}
              mask={'aa 9999999'}
            />
          </div>
          <div className="col-2 col-xs-12">
            {/* PASSPORT ISSUE DATE */}
            <DatePicker
              testName={'passportIAt'}
              testErrorName={'error-passportIAt'}
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="passportIAt"
              onChange={data => dispatch({ type: actions.PASSPORT_IAT, data })}
              value={state.passportIAt}
              required
              valid={
                !invalidFields.includes('passportIAt') &&
                validateDate({
                  current: state.passportIAt,
                  min: yearsAgo(130),
                  max: new Date(),
                })
              }
              onBlur={() => setFocus('')}
              curr={new Date()}
              min={
                new Date(new Date().setFullYear(new Date().getFullYear() - 10))
              }
              max={new Date()}
            />
          </div>
          <div className="col-8 col-xs-12">
            {/* PASSPORT ISSUED BY */}
            <TextInput
              testName={'passportIAt'}
              testErrorName={'error-passportIAt'}
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.issuedBy}
              onChange={passportIssuedBy =>
                dispatch({
                  type: actions.PASSPORT_ISSUED_BY,
                  data: passportIssuedBy.toLocaleUpperCase(),
                })
              }
              value={state.passportIssuedBy}
              required
              valid={
                !invalidFields.includes('passportIssuedBy')
              }
              onBlur={() => setFocus('')}
            />
          </div>
          <div className="col-3 col-xs-12">
            {/* PASSPORT EXPIRY DATE */}
            <DatePicker
              testName={'passportExpiryDate'}
              testErrorName={'error-passportExpiryDate'}
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="passportExpiryDate"
              onChange={data =>
                dispatch({ type: actions.PASSPORT_EXPIRY_DATE, data })
              }
              value={state.passportExpiryDate}
              required
              valid={
                !invalidFields.includes('passportExpiryDate') &&
                validateDate({
                  current: state.passportExpiryDate,
                  min: new Date(),
                  max: new Date(
                    new Date().setFullYear(new Date().getFullYear() + 10)
                  ),
                })
              }
              onBlur={() => setFocus('')}
              min={
                new Date()
              }
              max={
                new Date(new Date().setFullYear(new Date().getFullYear() + 10))
              }
            />
          </div>
          <div className="col-3 col-xs-12">
            {/* PASSPORT DATE BOARDING*/}
            <DatePicker
              testName={'passportDateBoardCrossing'}
              testErrorName={'error-passportDateBoardCrossing'}
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="passportDateBoardCrossing"
              onChange={data =>
                dispatch({
                  type: actions.PASSPORT_DATE_BOARD_CROSSING,
                  data,
                })
              }
              value={state.passportDateBoardCrossing}
              required
              valid={
                !invalidFields.includes('passportDateBoardCrossing') &&
                validateDate({
                  current: state.passportDateBoardCrossing,
                  min: yearsAgo(130),
                  max: new Date(),
                })
              }
              onBlur={() => setFocus('')}
              max={new Date()}
            />
          </div>
          <div className="col-4 col-xs-12">
            <FileInput
              testName={'udvFileMainFiscal'}
              testErrorName={'error-udvFileMainFiscal'}
              disabled={!state.agreed}
              lang={lang}
              url={UPLOADS_ENDPOINT}
              autorization={state.formId}
              target={state.documentsImages}
              content={accountForm.mainImageFile}
              dispatch={dispatch}
              onChange={data => {
                dispatch({
                  type: actions.F_SCAN_DIRECTORS_PASSPORT,
                  data: data,
                })
              }}
              valid={!invalidFields.includes('F_Scan_Directors_Passport')}
              documentType="Passport1"
              value={
                state.F_Scan_Directors_Passport[
                  state.F_Scan_Directors_Passport.length - 1
                ]
              }
            />
          </div>
          <div className="col-4 col-xs-12">
            <FileInput
              testName={'udvFileMainAddressFiscal'}
              testErrorName={'error-udvFileMainAddressFiscal'}
              disabled={!state.agreed}
              lang={lang}
              url={UPLOADS_ENDPOINT}
              autorization={state.formId}
              target={state.documentsImages}
              content={accountForm.arrivalStampFile}
              dispatch={dispatch}
              onChange={data => {
                dispatch({
                  type: actions.F_SCAN_DIRECTORS_PASSPORT_ADDRESS,
                  data: data,
                })
              }}
              valid={!invalidFields.includes('F_Scan_Directors_Passport_Address')}
              documentType="Passport2"
              value={
                state.F_Scan_Directors_Passport_Address[
                  state.F_Scan_Directors_Passport_Address.length - 1
                ]
              }
            />
          </div>
        </>
      ) : getPassportTypeTranslate(+state.passportType) ===
        'Вид на жительство' ? (
        <>
          <div className="col-2 col-xs-12">
            {/* PASSPORT PERMANENT RESIDENCY DATE */}
            <DatePicker
              testName={'passportDateIssuePermanentResidency'}
              testErrorName={'error-passportDateIssuePermanentResidency'}
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="passportDateIssuePermanentResidency"
              onChange={data =>
                dispatch({
                  type: actions.PASSPORT_DATE_ISSUE_PERMANENT_RESIDENCY,
                  data,
                })
              }
              value={state.passportDateIssuePermanentResidency}
              required
              valid={
                !invalidFields.includes(
                  'passportDateIssuePermanentResidency'
                ) &&
                validateDate({
                  current: state.passportDateIssuePermanentResidency,
                  min: yearsAgo(130),
                  max: new Date(),
                })
              }
              onBlur={() => setFocus('')}
              min={new Date(
                new Date().setFullYear(new Date().getFullYear() - 10) + (60 * 60 * 24 * 1000)
              )}
              max={new Date()}
            />
          </div>
          <div className="col-8 col-xs-12">
            {/* ISSUED BY */}
            <TextInput
              testName={'passportIssuedBy'}
              testErrorName={'error-passportIssuedBy'}
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.issuedBy}
              onChange={passportIssuedBy =>
                dispatch({
                  type: actions.PASSPORT_ISSUED_BY,
                  data: passportIssuedBy.toLocaleUpperCase(),
                })
              }
              value={state.passportIssuedBy}
              required
              valid={
                !invalidFields.includes('passportIssuedBy')
              }
              onBlur={() => setFocus('')}
            />
          </div>
          <div className="col-4 col-xs-12">
            <FileInput
              testName={'F_Scan_Directors_Passport'}
              testErrorName={'error-F_Scan_Directors_Passport'}
              disabled={!state.agreed}
              lang={lang}
              url={UPLOADS_ENDPOINT}
              autorization={state.formId}
              target={state.documentsImages}
              content={accountForm.residenceFileMainFiscal}
              dispatch={dispatch}
              onChange={data => {
                dispatch({
                  type: actions.F_SCAN_DIRECTORS_PASSPORT,
                  data: data,
                })
              }}
              valid={!invalidFields.includes('F_Scan_Directors_Passport')}
              documentType="Passport1"
              value={
                state.F_Scan_Directors_Passport[
                  state.F_Scan_Directors_Passport.length - 1
                ]
              }
            />
          </div>
          <div className="col-4 col-xs-12">
            <FileInput
              testName={'F_Scan_Directors_Passport_Address'}
              testErrorName={'error-F_Scan_Directors_Passport_Address'}
              disabled={!state.agreed}
              lang={lang}
              url={UPLOADS_ENDPOINT}
              autorization={state.formId}
              target={state.documentsImages}
              content={accountForm.residenceFileSecondFiscal}
              dispatch={dispatch}
              onChange={data => {
                dispatch({
                  type: actions.F_SCAN_DIRECTORS_PASSPORT_ADDRESS,
                  data: data,
                })
              }}
              valid={
                !invalidFields.includes('F_Scan_Directors_Passport_Address')
              }
              documentType="Passport2"
              value={
                state.F_Scan_Directors_Passport_Address[
                  state.F_Scan_Directors_Passport_Address.length - 1
                ]
              }
            />
          </div>
        </>
      ) : getPassportTypeTranslate(+state.passportType) === 'ID карта' ? (
        <>
          <div className="col-4 col-xs-12">
            {/* PASSPORT_CARD_NUMBER */}
            <TextInput
              testName={'passportCardNumber'}
              testErrorName={'error-passportCardNumber'}
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.documentNumberKgz}
              onChange={passportCardNumber => {
                dispatch({
                  type: actions.PASSPORT_CARD_NUMBER,
                  data: passportCardNumber,
                })
              }}
              value={state.passportCardNumber}
              required
              valid={!invalidFields.includes('passportCardNumber')}
              onBlur={() => setFocus('')}
              mask={'aa 9999999'}
            />
          </div>
          <div className="col-8 col-xs-12">
            {/* PASSPORT ISSUED BY */}
            <TextInput
              testName={'passportIssuedBy'}
              testErrorName={'error-passportIssuedBy'}
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.issuedBy}
              onChange={passportIssuedBy =>
                dispatch({
                  type: actions.PASSPORT_ISSUED_BY,
                  data: passportIssuedBy.toLocaleUpperCase(),
                })
              }
              value={state.passportIssuedBy}
              required
              valid={
                !invalidFields.includes('passportIssuedBy')
              }
              onBlur={() => setFocus('')}
            />
          </div>
          <div className="col-2 col-xs-12">
            {/* PASSPORT ISSUE DATE */}
            <DatePicker
              testName={'passportIAt'}
              testErrorName={'error-passportIAt'}
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="passportIAt"
              onChange={data => dispatch({ type: actions.PASSPORT_IAT, data })}
              value={state.passportIAt}
              required
              valid={
                !invalidFields.includes('passportIAt') &&
                validateDate({
                  current: state.passportIAt,
                  min: yearsAgo(130),
                  max: new Date(),
                })
              }
              onBlur={() => setFocus('')}
              curr={
                state.birthDate
                  ? new Date(
                      new Date(state.birthDate).setFullYear(
                        new Date(state.birthDate).getFullYear() + 16
                      )
                    )
                  : new Date()
              }
              min={
                state.birthDate
                  ? new Date(
                      new Date(state.birthDate).setFullYear(
                        new Date(state.birthDate).getFullYear() + 16
                      )
                    )
                  : new Date(
                      new Date().setFullYear(new Date().getFullYear() - 67)
                    )
              }
              max={new Date()}
            />
          </div>
          <div className="col-3 col-xs-12">
            {/* PASSPORT DATE BOARDING*/}
            <DatePicker
              testName={'passportDateBoardCrossing'}
              testErrorName={'error-passportDateBoardCrossing'}
              disabled={!state.agreed}
              lang={lang}
              accountForm={accountForm}
              accountFormKey="passportDateBoardCrossing"
              onChange={data =>
                dispatch({
                  type: actions.PASSPORT_DATE_BOARD_CROSSING,
                  data,
                })
              }
              value={state.passportDateBoardCrossing}
              required
              valid={
                !invalidFields.includes('passportDateBoardCrossing') &&
                validateDate({
                  current: state.passportDateBoardCrossing,
                  min: yearsAgo(130),
                  max: new Date(),
                })
              }
              onBlur={() => setFocus('')}
              max={new Date()}
            />
          </div>
          <div className="col-4 col-xs-12">
            <FileInput
              testName={'udvFileMainFiscal'}
              testErrorName={'error-udvFileMainFiscal'}
              disabled={!state.agreed}
              lang={lang}
              url={UPLOADS_ENDPOINT}
              autorization={state.formId}
              target={state.documentsImages}
              content={accountForm.idFile}
              dispatch={dispatch}
              onChange={data => {
                dispatch({
                  type: actions.F_SCAN_DIRECTORS_PASSPORT,
                  data: data,
                })
              }}
              valid={!invalidFields.includes('F_Scan_Directors_Passport')}
              documentType="Passport1"
              value={
                state.F_Scan_Directors_Passport[
                  state.F_Scan_Directors_Passport.length - 1
                ]
              }
            />
          </div>
          <div className="col-4 col-xs-12">
            <FileInput
              testName={'udvFileMainAddressFiscal'}
              testErrorName={'error-udvFileMainAddressFiscal'}
              disabled={!state.agreed}
              lang={lang}
              url={UPLOADS_ENDPOINT}
              autorization={state.formId}
              target={state.documentsImages}
              content={accountForm.residenceBacksideFile}
              dispatch={dispatch}
              onChange={data => {
                dispatch({
                  type: actions.F_SCAN_DIRECTORS_PASSPORT_ADDRESS,
                  data: data,
                })
              }}
              valid={!invalidFields.includes('F_Scan_Directors_Passport_Address')}
              documentType="Passport2"
              value={
                state.F_Scan_Directors_Passport_Address[
                  state.F_Scan_Directors_Passport_Address.length - 1
                ]
              }
            />
          </div>
          <div
            className={
              invalidFields.includes('passportDateBoardCheck')
                ? 'col-12 dateBoardCheckBox invalid'
                : 'col-12 dateBoardCheckBox'
            }
          >
            <input
              data-test-id="passportDateBoardCheck"
              id="passportDateBoardCheck"
              name="passportDateBoardCheck"
              type="checkBox"
              onChange={() =>
                dispatch({
                  type: actions.PASSPORT_DATE_BOARD_CROSSING_CHECK,
                  data: !state.passportDateBoardCheck,
                })
              }
              checked={state.passportDateBoardCheck ?? false}
            />
            <label htmlFor="passportDateBoardCheck">
              {accountForm.passportDateBoardCheck90.label[lang]}
            </label>
          </div>
        </>
      ) : null}
    </>
  )
}
