import React, { useRef, useState, useEffect } from 'react'

import { TextInputProps } from '../../types/common-types'
import { generateId, requestForm } from '../../utils/helpers'
import accountForm from '../../content/account-form'
import styles from '../Inputs/FileInput.module.scss'
import { actions } from '../../state-actions-reducers'

function FileInput({
  disabled,
  lang,
  content,
  onChange,
  value,
  className,
  valid,
  required,
  focus,
  url,
  autorization,
  target,
  documentType,
  singleType,
  onBlur,
  dispatch,
  ...props
}: TextInputProps) {
  const idRef = useRef(generateId(16))
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [focus])
  const [inputValue, setInputValue] = useState(
    value?.replace('C:\\fakepath\\', '') || content.placeholder[lang]
  )
  useEffect(() => {
    setInputValue(
      value?.replace('C:\\fakepath\\', '') || content.placeholder[lang]
    )
  }, [lang])
  const [errorResponse, setErrorResponse] = useState('')
  console.log('errorResponse', errorResponse)
  return (
    <div className={styles.fileInputBox}>
      <p className={styles.labelText}>
        {content.label[lang]}
        {required && <span className={styles.required}></span>}
      </p>
      <label
        htmlFor={idRef.current}
        className={`${className || ''} ${styles.label} ${
          valid ? '' : styles.invalid
        } ${disabled ? styles.disabled : ''}`.trim()}
      >
        <div className={styles.buttonBox}>
          <button className={styles.button}></button>
        </div>
        <span className={styles.valueText}>
          {inputValue}
          {required && <span className={styles.required}></span>}
        </span>
        <input
          data-test-id={props.testName}
          id={idRef.current}
          ref={inputRef}
          disabled={disabled}
          className={`${styles.input}`}
          type="file"
          placeholder={inputValue}
          onChange={async e => {
            console.log('e.target.value, e', e.target.value)
            if (e.target.value) {
              onChange(e.target.value, e)
              setInputValue(e.target.value.replace('C:\\fakepath\\', ''))
              const formData = new FormData()
              formData.append('File', e.target.files ? e.target.files[0] : '')

              const result = await requestForm(
                url,
                formData,
                {
                  authorization: `Bearer ${autorization}`,
                },
                true,
                'Error while token generation'
              )
              if (result.errorsStatus) {
                dispatch({
                  type: actions.IMAGE_ERROR,
                  data: true,
                })
                console.log(result.errorsStatus)
                result.errorsStatus === 415
                  ? setErrorResponse(accountForm.uploadErrorId1[lang])
                  : result.errorsStatus === 413
                  ? setErrorResponse(accountForm.uploadErrorId0[lang])
                  : setErrorResponse(accountForm.uploadErrorId2[lang])
              } else {
                dispatch({
                  type: actions.IMAGE_ERROR,
                  data: false,
                })
                setErrorResponse('')
                console.log('target', target)
                documentType
                  ? target.find((elem: { type: string; img: string }) => {
                      return elem.type === documentType
                    })?.type && !singleType
                    ? (target.find(
                        (elem: {
                          type: string
                          img: string
                          singleType: string
                        }) => {
                          return elem.type === documentType
                        }
                      ).img = result.url)
                    : target.find(
                        (elem: {
                          type: string
                          img: string
                          singleType: string
                        }) => {
                          return elem.singleType === singleType
                        }
                      )?.singleType
                    ? (target.find(
                        (elem: {
                          type: string
                          img: string
                          singleType: string
                        }) => {
                          return elem.singleType === singleType
                        }
                      ).img = result.url)
                    : target.push({
                        type: documentType,
                        img: result.url,
                        singleType: singleType ?? '',
                      })
                  : target.push(result)
              }
            }
          }}
          maxLength={500}
          onBlur={onBlur}
        />
      </label>
      {!valid && content.invalidText ? (
        <span data-test-id={props.testErrorName} className={styles.invalidText}>
          {content.invalidText[lang]}
        </span>
      ) : errorResponse.length > 0 ? (
        <span data-test-id={props.testErrorName} className={styles.invalidText}>
          {errorResponse}
        </span>
      ) : null}
    </div>
  )
}

export default FileInput
